
// project style hear start

.rts-single-project-one{
    background: #FFFFFF;
    border-radius:  5px;
    .thumbnail{
        display: block;
        overflow: hidden;
        border-radius: 5px 5px 0px 0px;
        position: relative;
        &::after{
            position: absolute;
            content: '01';
            right: 0;
            bottom: 0;
            width: 69px;
            height: 69px;
            background: var(--color-primary);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-weight: 700;
            font-size: 24px;
            line-height: 37px;
            font-family: var(--font-primary);
            border-radius: 5px 0 0 0;
        }
        img{
            border-radius: 5px 5px 0 0;
            transition: 1.3s;
            transform: scale(1);
            width: 100%;
        }
        &:hover{
            img{
                transform: scale(1.15);
            }
        }
        &.two{
            &::after{
                content: '02';
            }
        }
        &.three{
            &::after{
                content: '03';
            }
        }
    }
    .project-inner{
        padding: 40px;
        border-radius:  0 0 5px 5px;
        @media #{$small-mobile} {
            padding: 25px;
        }
        a.rts-btn{
            font-size: 14px;
            font-weight: 600;
            padding: 18px 28px;
        }
        a{
            .title{
                margin-bottom: 10px;
                transition: .3s;
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
        p{
            margin-bottom: 20px;
        }
    }
}
.rts-latest-project-area{
    &.home-seven{
        .elecate-center-title{
            .title{
                text-transform: unset;
            }
        }
        .rts-single-project-one{
            .thumbnail{
                &::after{
                    display: none;
                }
                .icon{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    img{
                        width: 90px;
                        height: 90px;
                        text-align: center;
                        padding: 25px;
                        display: block;
                        background: #FFFFFF;
                        border-radius: 0;
                        transform: scale(1) !important;
                    }
                }
            }
            .project-inner{
                background: #171717;
                padding: 20px 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .content{
                    .pre-title{
                        color: #424242;
                        margin-bottom: 10px;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 20px;
                    }
                    .title{
                        color: #FFFFFF;
                        transition: all .3s;
                        &:hover{
                            color: var(--color-primary);
                        }
                    }
                }
                .icon{
                    a{
                        background: var(--color-primary);
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        text-align: center;
                        display: block;
                        i{
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
    &.home-seven2{
        background: #F2F2F2;
        .heading-title{
            display: flex;
            align-items: flex-end; 
            justify-content: space-between;
            margin-bottom: 50px;
            .elecate-center-title{
                text-align: start;
                .title{
                    text-transform: unset;
                    font-weight: 600;
                    font-size: 48px;
                    line-height: 58px;
                    margin-bottom: 0;
                }
            }
            .right-side-btn{
                @media(max-width:768px){
                   display: none; 
                }
                .rts-btn{
                    border: 1px solid var(--color-primary);
                    background: transparent;
                    display: flex;
                    align-items: center;
                    color: #262262;
                    padding: 5px 5px 5px 24px;
                    &:hover{
                        color: #FFFFFF;
                        i{
                            background: #FFFFFF;
                            color: var(--color-primary);
                        }
                    }
                    i{
                        height: 40px;
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: var(--color-primary);
                        color: #FFFFFF;
                        margin-left: 20px;
                    }
                }
            }
        }
        .project-inner{
            padding: 0;
            .item{
                display: flex;
                align-items: center;
                padding: 20px 0;
                border-bottom: 1px solid rgba(0,0,0,.07);
                transition: all .4s;
                cursor: pointer;
                &:first-child{
                    border-top: 1px solid rgba(0,0,0,.07);
                }
                &:hover{
                    .pos{
                        background: var(--color-primary);
                        color: #FFFFFF;
                    }
                    .image-title{
                        &::before{
                            height: 100%;
                        }
                    }
                    .portfolio-button{
                        i{
                            transform: rotate(0deg);
                        }
                    }
                }
                .pos{
                    font-weight: 600;
                    font-size: 14px;
                    color: #262262;
                    background: #FFFFFF;
                    width: 36px;
                    height: 36px;
                    line-height: 36px;
                    border-radius: 50%;
                    text-align: center;
                    display: block;
                    transition: all .4s;
                }
                .image-title{
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 37px;
                    position: relative;
                    display: inline-block;
                    z-index: 2;
                    color: #262262;
                    transition: all .4s;
                    opacity: 1;
                    margin-left: 30px;
                    &::before{
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        content: attr(data-letters);
                        overflow: hidden;
                        white-space: nowrap;
                        transition: height 1s;
                        color: var(--color-primary);
                        height: 0;
                        z-index: 999;
                    }
                }
                .portfolio-button{
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    border-radius: 50%;
                    text-align: center;
                    display: block;
                    transition: all .4s;
                    background: #EFEFFE ;
                    color: var(--color-primary);
                    transition: all .4s;
                    &:hover{
                        background: var(--color-primary);
                        color: #fff;
                    }
                    i{
                        transform: rotate(-45deg);
                        transition: all .4s;
                    }
                    @media(max-width:768px){
                        display: none;
                    }
                }
            }
        }
        .rts-img-reveal-wrapper{
            width: 350px;
            height: 370px;
        }
        .rts-hover-wrapper{
            display: none;
        }
    }
}
.no-repeat{
    background-repeat: no-repeat;
    
}
.container-full-screen{
    width: 100%;
    margin: auto;
}
.title-wrapper-case{
    display: flex;
    justify-content: space-between;
    position: relative;
    .left-title-area-case{
        span.pre-title{
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1em;
            color: #FFFFFF;
            text-transform: uppercase;
        }
        .title{
            margin-top: 15px;
            font-weight: 600;
            font-size: 48px;
            line-height: 58px;
            color: #FFFFFF;
            font-family: var(--font-primary);
        }
    }
    .swiper-btn-case{
        display: flex;
        align-items: center;
        top: 50px;
        bottom: auto;
        @media(max-width:768px){
            display: none;
        }
        .swiper-button-next{
            background-image: none;
            position: relative;
            right: 0;
            left: auto;
            &::after{
                position: absolute;
                content: '\f061';
                font-size: 20px;
                color: #fff;
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                font-family: 'fontawesome';
                font-weight: 200;
                justify-content: center;
                border: 1px solid rgba(255, 255, 255, 0.2);
                transition: .3s;
            }
        }
        .swiper-button-prev{
            background-image: none;
            position: relative;
            right: 110px;
            left: auto;
            &::after{
                position: absolute;
                content: '\f060';
                font-size: 20px;
                color: #fff;
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                font-family: 'fontawesome';
                font-weight: 200;
                justify-content: center;
                border: 1px solid rgba(255, 255, 255, 0.2);
                transition: .3s;
            }
        }
    }
}
.swiper-button-next, .swiper-button-prev {
    top: 1%;
    &:hover{
        &::after{
            background: var(--color-primary);
            border: 1px solid var(--color-primary);
        }
    }
}
.case-three{
    .swiper-slide-active{
        .content{
            bottom: 0  !important;
        }
    }
}
.rts-single-case-three{
    position: relative;
    max-width: 100%;
    .wrapper{
        overflow: hidden;
        display: block;
        position: relative;
        .content{
            position: absolute;
            width: 100%;
            background: #F8F8F8;
            bottom: -250px;
            padding: 29.5px;
            transition: .6s;
            @media(max-width:1200px){
                padding: 10px 15px;
            }
            @media(max-width:768px){
                text-align: center;
            }
            .title{
                font-weight: 600;
                font-size: 30px;
                line-height: 46px;
                color: #262262;
                margin-bottom: 5px;
                @media(max-width:575px){
                    font-size: 24px;
                    line-height: 34px;
                }
            }
            p.disc{
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #6E777D;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                font-family: var(--font-primary);
            }
        }
    }
}
.title-wrapper-2-case{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .button-area{
        margin-bottom: 50px;
        @media(max-width:768px){
            display: none;
        }
        a.rts-view-more{
            display: flex;
            align-items: center;
            color: #262262;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            font-family: var(--font-primary);
            position: relative;
            z-index: 1;
            &::after{
                position: absolute;
                content: '';
                right: -50px;
                width: 100%;
                height: 100%;
                padding: 27px 25px;
                background: #F4F4F4;
                top: 50%;
                bottom: 0;
                z-index: -1;
                transform: translateY(-50%);
                border-radius: 2px;
                transition: .3s;
            }
            i{
                margin-left: 10px;
                color: #262262;
            }
            &:hover{
                &::after{
                    right: -25px;
                    width: 130%;
                }
            }
        }
    }
}
// case two wrapper

.cse-two-wrapper{
    position: relative;
    z-index: 1;
    display: block;
    overflow: hidden;
    &:hover{
        img.main{
            transform: scale(1.15);
        }
    }
    img.main{
        width: 100%;
        transition: 1.3s;
    }
    &::after{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #00000065;
        z-index: 0;
        opacity: 0;
        transition: .3s;
    }
    &::before{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        bottom: -7px;
        background-image: url(../images/project/icon/02.png);
        z-index: 0;
        opacity: 0;
        transition: .3s;
        background-repeat: no-repeat;
        background-position: right, bottom;
    }
    .icon{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        z-index: 2;
        transition: 1s;
        @media(max-width:991px) and (min-width:768px){
            width: 70px;
        }
        @media(max-width:500px){
            width: 70px;
        }
    }
}

.case-two{
    .swiper-wrapper{
        .swiper-slide{
            &.swiper-slide-active{
                .cse-two-wrapper{
                    img.main{
                        transform: scale(1.15);
                        filter: grayscale(1);
                    }
                    &::after{
                        opacity: 1;
                    }
                    &::before{
                        opacity: 1;
                        z-index: 2;
                    }
                    .icon{
                        transform: translate(-50%, -50%) scale(1);
                    }
                }
            }
        }
    }
}

.head-engineer-wrapper{
    display: flex;
    align-items: center;
    @media(max-width:991px){
        margin-bottom: 50px;
    }
    @media(max-width:768px){
        display: block;
    }
    .thumbnail{
        width: 100%;
        margin-right: 40px;
        img{
            width: 100%;
        }
        @media(max-width:768px){
            margin-bottom: 30px;
        }
    }
    .inner-content{
        margin-left: 15px;
        p.quote{
            color: #262262;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            padding-left: 20px;
            border-left: 2px solid #262262;
            margin-bottom: 60px;
        }
    }
}

.personal-skill-two-wrapper{
    padding: 30px;
    border: 1px solid #E9E9E9;
    .image-area{
        display: flex;
        align-items: center;
        gap: 20px;
        padding-bottom: 30px;
        border-bottom: 1px solid #E9E9E9;
        @media(max-width:1200px) and (min-width:991px){
            display: block;
        }
        a{
            display: block;
            overflow: hidden;
            img{
                width: 100%;
                transition: .3s;
            }
            &:hover{
                img{
                    transform: scale(1.2);
                }
            }
        }
        .content{
            @media(max-width:1200px) and (min-width:991px){
                text-align: center;
                margin-top: 20px;
            }
            .name{
                margin-bottom: 5px;
            }
            span{
                margin-bottom: 5px;
            }
        }
    }
}

.social-transparent{
    margin-top: -10px;
    ul{
        display: flex;
        align-items: center;
        list-style: none;
        padding-left: 0;
        margin: 0;
        margin-top: 15px;
        gap: 20px;
        @media(max-width:1200px) and (min-width:991px){
            justify-content: center;
        }
        li{
            margin: 0;
            a{
                i{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    color: #262262;
                }
            }
        }
    }
}

.progress-wrapper-area-two{
    margin-top: 30px;
    .single-progress{
        margin-bottom: 22px;
        &:last-child{
            margin-bottom: 0;
        }
        .top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            span{
                font-weight: 400;
                font-size: 14px;
                line-height: 15px;
                color: #262262;
            }
        }
        .progress{
            border-radius: 0;
            height: 6px;
            .progress-bar{
                background-color: var(--color-primary);
                border-radius: 0;
            }
        }
    }
}

.single-case-wrapper{
    position: relative;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    transform: scale(.97);
    a{
        display: block;
        overflow: hidden;
        img{
            width: 100%;
            transition: 1.3s;
        }
        &:hover{
            img{
                transform: scale(1.15);
            }
        }
    }
    .content{
        position: absolute;
        background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        bottom: -30px;
        left: 30px;
        right: 30px;
        display: block;
        width: 90%;
        padding: 33px;
        opacity: 0;
        transition: 1s;
        @media #{$smlg-device} {
            padding: 10px;
            font-size: 18px;
            left: 15px;
            right: 15px;
        }
        a{
            .title{
                margin-bottom: 5px;
                @media #{$smlg-device} {
                    font-size: 18px;
                }
            }
        }
    }
}
.projects-wrapper-inner-page .single-case-wrapper:hover .content span {
    color: #676767 !important;
    @media(max-width:1199px){
        font-size: 16px;
    }
}

.main-wrapper-case-swiper-tab{
    .swiper-slide{
        &.swiper-slide-active{
            .single-case-wrapper{
                transform: scale(1.03);
                .content{
                    opacity: 1;
                    bottom: 30px !important;
                }
            }
        }
    }
}

.main-wrapper-case-swiper-tab{
    padding: 11px 0 110px 0 !important;
}

.case-tab-swipers{
    position: relative;
    .swiper-paginations{
        position: absolute;
        bottom: -108px;
        left: 47.5%;
        max-width: max-content;
        background: #fff;
        padding: 12px 15px;
        border-radius: 33px;
        @media #{$smlg-device} {
            left: 44.5%;
        }
        @media #{$large-mobile} {
            bottom: -98px;
            left: 38.5%;
        }
        @media #{$small-mobile} {
            left: 33.5%;
        }
        .swiper-pagination-bullet{
            width: 9px;
            height: 9px;
            background: #EDEDED;
            border-radius: 50%;
            opacity: 1;
        }
        .swiper-pagination-bullet-active{
            width: 9px;
            height: 9px;
            background: var(--color-primary);
            border-radius: 50%;
            opacity: 1;
        }
    }
}


.title-wrapper-casestudies-one{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .next-prev-btn{
        @media #{$large-mobile} {
            display: none;
        }
        .swiper-button-next,
        .swiper-button-prev{
            background-image: none;
            top: 33px;
            i{
                font-size: 22px;
                color: #262262;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: 60px;
                border-radius: 50%;
                background: #fff;
                transition: .3s;
            }
            &:hover{
                i{
                    background: var(--color-primary);
                    color: #fff;
                }
            }
        }
        .swiper-button-next{
            right: 40px;
        }
        .swiper-button-prev{
            right: 120px;
            left: auto;
        }
    }
}


.big-bg-porduct-details{
    position: relative;
    img{
        width: 100%;
        border-radius: 0;
    }
    .project-info{
        position: absolute;
        right: 0;
        bottom: 0;
        background: #fff;
        width: 386px;
        box-shadow: 0px 10px 21px rgba(27, 18, 18, 0.05);
        border-radius: 0 0 0 0;
        @media #{$smlg-device} {
            bottom: -66px;
        }
        @media #{$md-layout} {
            position: relative;
            width: 100%;
            border-radius: 0;
            margin-bottom: 30px;
            margin-top: -33px;
        }
        @media #{$sm-layout} {
            position: relative;
            width: 100%;
            border-radius: 0;
            margin-bottom: 30px;
            margin-top: -33px;
        }
        .info-head{
            padding: 24px 40px;
            background: var(--color-primary);
            border-radius: 0 0 0 0;
            @media #{$md-layout} {
                border-radius: 0 0 0 0;
            }
            @media #{$sm-layout} {
                border-radius: 0 0 0 0;
            }
            @media #{$small-mobile} {
                padding: 12px 14px;
            }
            .title{
                color: #fff;
                margin-bottom: 0;
                @media #{$small-mobile} {
                    font-size: 18px;
                }
            }
        }
        .info-body{
            padding: 15px 40px;
            @media #{$small-mobile} {
                padding: 15px 10px;
            }
            .single-info{
                display: flex;
                align-items: center;
                padding: 24px 0;
                border-bottom: 1px solid #E9E9E9;
                @media #{$small-mobile} {
                    padding: 15px 0;
                }
                &:last-child{
                    border-bottom: none;
                }
                .info-ico{
                    i{
                        height: 50px;
                        width: 50px;
                        background: #F9F0F0;
                        color: var(--color-primary);
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                    }
                }
                .info-details{
                    margin-left: 20px;
                    span{
                        margin-bottom: 5px;
                    }
                    .name{
                        font-size: 18px;
                        margin-bottom: 0;
                        margin-top: 3px;
                    }
                }
            }
        }
    }
}



.product-details-main-inner {
    @media #{$small-mobile} {
        padding: 0 10px;
    }
}
// detaols
.product-details-main-inner{
    span{
        color: var(--color-primary);
        font-weight: 500;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        font-family: var(--font-primary);
    }
    .title{
        margin-bottom: 20px;
    }
    p{
        &.disc{
            font-size: 16px;
            margin-bottom: 25px;
        }
        &.italic{
            font-size: 20px;
            color: #1C2539;
            font-weight: 600;
            margin-bottom: 10px;
            line-height: 30px;
            font-family: var(--font-primary);
        }
    }
}

.single-project-details-challenge{
    margin-bottom: 20px;
    display: flex;
    .details{
        color: #1C2539;
        line-height: 26px;
        font-weight: 400;
        font-size: 16px;
    }
    &:last-child{
        margin-bottom: 0;
    }
    .icon{
        margin-right: 15px;
        i{
            width: max-content;
            border-radius: 50%;
            color: var(--color-primary);
            font-size: 19px;
            margin-top: 4px;
        }
    }
}


.rts-project-details-thumbnail{
    .thumbnail{
        position: relative;
        display: block;
        transition: .3s;
        overflow: hidden;
        img{
            transition: .6s;
            width: 100%;
        }
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
    }
}



.rts-left-write-blog-wrapper-main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px;
    background: #F8F8F8;
    @media #{$sm-layout} {
        padding: 25px;
    }
    .single{
        display: flex;
        align-items: center;
        gap: 50px;
        cursor: pointer;
        .writing-content{
            span{
                display: block;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #6E777D;
                font-family: var(--font-primary);
                transition: .3s;
            }
            .title{
                font-weight: 600;
                font-size: 24px;
                line-height: 37px;
                color: #262262;
                transition: .3s;
                @media #{$sm-layout} {
                    font-size: 16px;
                }
            }
            &:hover{
                span{
                    color: var(--color-primary);
                }
                .title{
                    color: var(--color-primary);
                }
            }
        }
        .icon-1{
            i{
                color: #262262;
            }
            &:hover{
                i{
                    color: var(--color-primary);
                }
            }
        }
        &.right-icon-area{
            text-align: right;
        }
        &.left-icon-area{
            text-align: left;
        }
    }
}



.projects-wrapper-inner-page{
    ul.nav{
        padding: 0 ;
        margin: 0;
        justify-content: center;
        border: none;
        li{
            margin: 20px 20px;
            @media #{$sm-layout} {
                margin: 5px 20px;
            }
            button{
                padding: 0;
                border: none;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #262262;
                &[aria-selected="true"]{
                    color: var(--color-primary);
                    position: relative;
                    &::after{
                        position: absolute;
                        content: '';
                        left: 0;
                        bottom: -1px;
                        height: 2px;
                        width: 100%;
                        background: var(--color-primary);
                    }
                }
            }
        }
    }
}



.projects-wrapper-inner-page{
    .single-case-wrapper{
        transform: scale(1);
        a{
            img.main-2{
                width: 100%;
            }
        }
        &:hover{
            .content{
                opacity: 1;
                bottom: 30px !important;
                @media #{$smlg-device} {
                    bottom: 15px;
                }
                span{
                    @media #{$smlg-device} {
                        color: #1f1f1f;
                    }
                }
            }
        }
    }
}

.home-four-case{
    .single-case-wrapper{
        .content{
            opacity: 1;
            bottom: 30px !important;
        }
    }
}

.bg-h-5-project{
    background-image: url(../images/project/30.jpg);
}

.latest-project-horizental-title-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
    }
    .title{
        color: #ffff !important;
        br{
            @media #{$small-mobile} {
                display: none;
            }
        }
        @media(max-width:991px) and (min-width:768px) {
            br{
                display: none;
            }
        }
    }
}
.thumbnail-pc img {
    width: 100%;
}

.single-varticle-product{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 0;
    border-bottom: 1px solid #212121;
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
    }
    &:first-child{
        border-top: 1px solid #212121;
    }
    .name-area{
        display: flex;
        align-items: center;
        flex-basis: 50%;
        @media #{$sm-layout} {
            margin-bottom: 10px;
        }
        span{
            height: 45px;
            width: 45px;
            background: var(--color-primary);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
        }
        a{
            .title{
                transition: .3s;
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
        .title{
            margin-bottom: 0;
            margin-left: 20px;
            color: #fff;
            font-weight: 400;
            font-size: 24px;
            line-height: 37px;
            @media #{$small-mobile} {
                font-size: 16px;
                line-height: 37px;
            }
        }
    }
    .mid-disc{
        p.disc{
            color: #656565;
            @media #{$sm-layout} {
                margin-bottom: 10px;
            }
            br{
                @media #{$sm-layout} {
                    display: none;
                }
            }
        }
    }
    .end{
        button{
            i{
                color: #fff;
                font-size: 22px;
                font-weight: 400;
            }
        }
    }
}

.thumbnail-pc{
    @media #{$sm-layout} {
        max-width: 100%;
    }
}

.project-title-btn-wrapper-8{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media #{$large-mobile} {
        flex-direction: column;
        align-items: flex-start;
    }
    span.pre{
        text-transform: uppercase;
        color: var(--color-primary);
        font-family: var(--font-primary);
    }
    
}

.rts-third-section-wrapper{
    background: #F8F8F8;
}

.home-nine-swiper-area{
    overflow: hidden;
    position: relative;
}

.nav-wrapper-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .main-title-area-center{
        text-align: left !important;
    }
    .swiper-pagination{
        left: auto;
        right: 0;
        margin-top: 90px;
        display: flex;
        align-items: center;
        .swiper-pagination-bullet{
            background: #c0c0c0;
            opacity: 1;
            margin-left: 30px;
            height: 5px;
            width: 5px;
        }
        .swiper-pagination-bullet-active{
            background: none;
            background-image: url(../images/project/icon/03.png);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            opacity: 1;
            height: 21px;
            width: 21px;
        }
    }
}


body.index-nine{
    .single-case-wrapper{
        border-radius: 0;
    }
}

.home-nine-swiper-area{
    .project-home-nine{
        .swiper-wrapper{
            .swiper-slide.swiper-slide-active{
                margin-top: 50px;
            }
        }
    }   
}

.about-inner-left-nine{
    .main-title-area-center{
        text-align: left !important;
    }
}

.background-dark-ten-portfolio{
    position: relative;
    z-index: 1;
    &::after{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        height: 617px;
        background: #171717;
        width: 100%;
        z-index: -1;
    }
    .heading-title{
        .title{
            color: #fff;
            text-transform: capitalize;
        }
    }
}
.mt-dec-process{
    margin-top: -150px;
}


.case-tab-swipers-11{
    .swiper-slide.swiper-slide-active{
        .single-case-wrapper{
            transform: none !important;
        }
    } 
    .swiper-slide{
        border-radius: 0;
        .single-case-wrapper{
            transform: none;
            border-radius: 0;
        }
    }  
}